import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { DOCUMENT } from '@angular/common';
import { Component, inject, Inject, OnDestroy } from '@angular/core';

import { ButtonComponent } from '@app/components/button/button.component';
import { QuestionModal } from '@app/models/modals/question-modal.model';

@Component({
    selector: 'app-modal-question',
    standalone: true,
    imports: [ButtonComponent],
    templateUrl: './modal-question.component.html',
    styleUrl: './modal-question.component.sass',
})
export class ModalQuestionComponent implements OnDestroy {
    questionModal: QuestionModal;
    btnCancel: string;
    btnSuccess: string;
    private readonly document = inject(DOCUMENT);

    constructor(
        public dialogRef: DialogRef<boolean>,
        @Inject(DIALOG_DATA) public data: QuestionModal
    ) {
        this.questionModal = data;
        this.btnCancel = '';
        this.btnSuccess = '';

        switch (this.questionModal.option) {
            case 'update':
            case 'follower':
                {
                    this.btnCancel = 'Cancelar';
                    this.btnSuccess = 'Continuar';
                }
                break;

            case 'deletePublicationImage':
            case 'deletePreference':
            case 'deletePublication':
                {
                    this.btnCancel = 'Cancelar';
                    this.btnSuccess = 'Eliminar';
                }
                break;

            case 'match':
                {
                    this.btnCancel = 'Cancelar';
                    this.btnSuccess = 'Enviar';
                }
                break;

            case 'register':
                {
                    this.btnCancel = 'Cancelar';
                    this.btnSuccess = 'Publicar';
                }
                break;
            case 'closeSession':
                {
                    this.btnCancel = 'Aceptar';
                    this.btnSuccess = 'Cancelar';
                }
                break;
        }

        this.document.body.style.overflow = 'hidden';
    }

    ngOnDestroy(): void {
        this.document.body.style.overflowX = 'hidden';
        this.document.body.style.overflowY = 'auto';
    }

    closeModal(res: boolean = false): void {
        this.dialogRef.close(res);
    }
}
